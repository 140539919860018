export default [
    {
        path: '/system/bannerManage',
        name: 'bannerManage',
        component: () => import('@/views/bannerManage/bannerManage.vue'),
        icon: 'a',
        title: 'Banner管理'
    },
    {
        path: '/system/materialManage',
        name: 'materialManage',
        component: () => import('@/views/materialManage/materialManage.vue'),
        icon: 'c',
        title: '素材管理'
    },
    {
        path: '/system/categoryManage',
        name: 'categoryManage',
        component: () => import('@/views/categoryManage/categoryManage.vue'),
        icon: 'c',
        title: '分类管理'
    },
    {
        path: '/system/activityManage',
        name: 'activityManage',
        component: () => import('@/views/activityManage/activityManage.vue'),
        icon: 'c',
        title: '活动管理'
    },
    {
        path: '/system/branchManage',
        name: 'branchManage',
        component: () => import('@/views/branchManage/branchManage.vue'),
        icon: 'c',
        title: '机构管理'
    },
    {
        path: '/system/itemManage',
        name: 'quite',
        // component: () => import('@/views/itemManage/itemManage.vue'),
        icon: 'd',
        title: '退出登录',
        action: 'logout',
        noAction: true
    }
]
